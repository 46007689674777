var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('make-target',{attrs:{"dialog":_vm.dialog},on:{"updated":function (_) {
        _vm.dialog = _;
      },"added":function (_) {
        _vm.dialog = _;
        _vm.getTargets();
      }}}),_c('div',{staticClass:"d-flex align-content-center justify-space-between"},[_c('h1',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t("targets")))]),_c('v-btn',{attrs:{"x-large":"","color":"secondary"},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("make-a-target"))+" ")],1)],1),_c('v-card',{staticClass:"transparent",attrs:{"loading":_vm.loading}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('label',{attrs:{"for":"se"}},[_vm._v(_vm._s(_vm.$t("search")))]),_c('v-text-field',{staticClass:"mt-2",attrs:{"hide-detials":"auto","label":_vm.$t('search'),"id":"se","solo":"","loading":_vm.loading,"clearable":"","append-icon":"mdi-magnify","type":"search"},model:{value:(_vm.options.Search),callback:function ($$v) {_vm.$set(_vm.options, "Search", $$v)},expression:"options.Search"}})],1),_c('v-col',{staticStyle:{"padding-top":"0"},attrs:{"cols":"12"}},[_c('v-chip-group',{attrs:{"loading":_vm.loading,"active-class":"success"},on:{"change":function($event){return _vm.getTargets()}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},_vm._l((_vm.statues),function(item,index){return _c('v-chip',{key:index,attrs:{"x-large":"","filter":"","value":item.key}},[_vm._v(" "+_vm._s(item.text)+" ")])}),1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"disable-pagination":"","headers":_vm.headers,"items":_vm.targets,"no-data-text":_vm.$t('no-data'),"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.userFullName",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.userFullName !== null ? item.userFullName : item.companyName)+" ")]}},{key:"item.dueDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$service.formatDate(new Date(item.dueDate)))+" ")]}},{key:"item.isDone",fn:function(ref){
      var item = ref.item;
return [(new Date(item.dueDate).getTime() < new Date().getTime())?[_c('v-chip',{attrs:{"label":"","color":item.isDone ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.isDone ? _vm.$t('done') : _vm.$t('overdue'))+" ")])]:[_c('v-chip',{attrs:{"label":"","color":item.isDone ? 'success' : 'warning'}},[_vm._v(" "+_vm._s(item.isDone ? _vm.$t('done') : _vm.$t('waiting'))+" ")])]]}}])}),_c('div',{staticClass:"d-flex justify-center"},[_c('v-pagination',{attrs:{"length":parseInt(_vm.total / _vm.options.PageSize) + 1},model:{value:(_vm.options.PageIndex),callback:function ($$v) {_vm.$set(_vm.options, "PageIndex", $$v)},expression:"options.PageIndex"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }