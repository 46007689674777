<template>
  <div>
    <!-- use drawer -->
    <drawer title="Management" :items="drawer.items" icon="project-management">
    </drawer>
    <!-- use targets -->
    <targets v-if="tab == 'targets'"></targets>
    <!-- use plans -->
    <plans v-if="tab == 'plans'"></plans>
    <!-- use fms -->
    <FMS v-if="tab == 'fms'"></FMS>
  </div>
</template>
<script>
// import drawer comonent
import Drawer from "@/layouts/Drawer";
// import targets component
import Targets from "./targets/Targets.vue";
// import plans component
import Plans from "./plans/WorkPlans.vue";
// import fms component
import FMS from "./fms/FMS.vue";
export default {
  components: {
    Drawer,
    Targets,
    Plans,
    FMS
  },
  data() {
    return {
      drawer: {
        items: [
          {
            title: this.$t('targets'),
            icon: "mdi-account-multiple",
            route: "management-targets",
            access: this.$service.hideFrom((v) => [v == 1])
          },
          {
            title: this.$t('plans'),
            route: "management-plans",
            icon: "mdi-calendar-multiple-check",
            access: this.$service.hideFrom((v) => [v == 1])
          },
          {
            title: this.$t('fms'),
            route: "management-fms",
            icon: "mdi-file-cabinet",
          }
        ],
      },
    };
  },

  computed: {
    tab() {
      return this.$route.path.split("/")[2];
    },
  },
  created() {
    if (this.tab == undefined || this.tab == "") {
      this.$router.push({
        name: this.drawer.items.filter((v) => v.access == true || v.access == undefined)[0].route,
      });
    }
  },
  beforeMount() {
    this.$store.commit("TOGGLE_DRAWER", true);
  },
};
</script>