<template>
  <v-dialog persistent v-model="dialog" width="800">
    <v-card :loading="loading" outlined>
      <v-card-title class="text-h5 my-3">
        {{ $t("add-work-plan") }}
        <v-spacer></v-spacer>
        <v-btn @click="dialog = !dialog" text color="error">
          <f-icon icon="times-circle" />
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-10">
        <v-form :loading="loading" ref="">
          <v-row>
            <v-col cols="12">
              <v-text-field
                hide-detials="auto"
                v-model="inputs.name"
                :label="$t('name')"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="inputs.companyId"
                :label="$t('company')"
                outlined
                :items="companies"
                item-text="name"
                :disabled="loading"
                item-value="id"
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <div class="d-flex align-center justify-end py-3 px-3">
        <v-btn color="error" class="mx-1" outlined @click="dialog = !dialog">
          <f-icon icon="times-circle" />
          {{ $t('cancel') }}</v-btn
        >
        <v-btn color="primary" :loading="loading" @click="addWorkPlan">
          <v-icon>mdi-plus</v-icon>
          {{ $t('save') }}</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      inputs: {
        name: "",
        companyId: "",
      },
      companies: [],
    };
  },
  created() {
    this.$http
      .get("/Companies", {
        params: {
          OfficeId: this.$store.state.userInfo.office.id,
        },
      })
      .then((com) => {
        this.companies = com.data.result;
        this.loading = false;
      });
  },
  methods: {
    async addWorkPlan() {
      this.loading = true;
      try {
        await this.$http.post("/WorkPlanTypes/post", this.inputs);
        this.dialog = false;
        this.$emit("added", !this.dialog);
      } catch (e) {
        this.$store.commit("UPDATE_SNACKBAR", true);

        this.$store.commit("UPDATE_SNACKBAR_MESSAGE", e.response.data.message);
      }
      this.loading = false;
    },
  },
  props: ["dialog"],
  watch: {
    dialog() {
      this.$emit("updated", this.dialog);
    },
  },
};
</script>
