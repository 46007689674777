<template>
  <v-dialog persistent v-model="dialog" width="800">
    <v-card :loading="loading" outlined>
      <v-card-title class="my-2">
        {{ $t("make-a-target") }}
        <!-- <span class="primary white--text pa-1 mx-1 rounded">
          Current: {{ this.slide + 1 }}
        </span> -->

        <v-spacer></v-spacer>
        <v-btn @click="dialog = !dialog" text color="error">
          <f-icon icon="times-circle" />
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form :loading="loading" ref="makeTarget">
          <!-- <v-window continuous v-model="slide" vertical>
            <v-window-item v-for="(input, n) in inputs" :key="`card-${n}`"> -->
          <v-row
            class="mt-1 justify-space-between"
            align="start"
            justify="space-between"
          >
            <v-col cols="6">
              <v-autocomplete
                outlined
                :label="$t('products')"
                :no-data-text="$t('write-name-to-search')"
                :items="products"
                item-text="tradeName"
                hide-details="auto"
                v-model="inputs.productId"
                :rules="[(v) => !!v || $t('this-field-is-requird')]"
                :search-input.sync="searchProduct"
                item-value="id"
                :loading="loading"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="removeProduct(data.item)"
                  >
                    {{ data.item.tradeName }}
                    <small class="mx-1">{{ data.item.scientificName }}</small>
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.tradeName"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.scientificName"
                      ></v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-html="data.item.productDosageFormName"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <!-- <v-col cols="2">
              <v-radio-group hide-details="auto" row v-model="inputs.type">
                <v-radio label="Number" :value="true"></v-radio>
                <v-radio label="Money" :value="false"></v-radio>
              </v-radio-group>
            </v-col> -->
            <v-col cols="12" md="6">
              <v-text-field
                hide-detials="auto"
                v-model="inputs.value"
                :label="$t('target-number')"
                @input="inputs.value = $service.formatCurrency(inputs.value)"
                placeholder="0.0"
                :pattern="/^-?\d+\.?\d*$/"
                :append-icon="
                  inputs.type ? 'mdi-sort-numeric-variant' : 'mdi-currency-usd'
                "
                :rules="[(v) => !!v || $t('this-field-is-requird')]"
                outlined
              >
                <template v-slot:append>
                  <v-btn-toggle
                    active-class="secondary"
                    class="d-flex align-center transparent "
                    tile
                    v-model="inputs.type"
                  >
                    <v-btn text small :value="0">
                      <v-icon :color="inputs.type == 0 ? 'white' : 'black'"
                        >mdi-sort-numeric-variant</v-icon
                      >
                    </v-btn>
                    <v-btn text small :value="1">
                      <v-icon :color="inputs.type == 1 ? 'white' : 'black'"
                        >mdi-currency-usd</v-icon
                      >
                    </v-btn>
                  </v-btn-toggle>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="6">
              <v-autocomplete
                outlined
                :label="$t('user')"
                :no-data-text="$t('write-name-to-search')"
                :items="users"
                item-text="fullName"
                hide-details="auto"
                multiple
                v-model="inputs.users"
                :rules="[(v) => !!v || $t('this-field-is-requird')]"
                :search-input.sync="search"
                item-value="id"
                :loading="loading"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="deleteUser(data.index)"
                  >
                    {{ data.item.fullName }}
                    <small class="mx-1">{{ data.item.userTypeName }}</small>
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.fullName"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.userTypeName"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    hide-detials="auto"
                    outlined
                    v-model="inputs.dueDate"
                    :label="$t('due-date')"
                    :rules="[(v) => !!v || $t('this-field-is-requird')]"
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="inputs.dueDate"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-textarea
                hide-detials="auto"
                outlined
                v-model="inputs.note"
                :label="$t('note')"
              ></v-textarea>
            </v-col>
          </v-row>
          <!-- </v-window-item>
          </v-window> -->
        </v-form>
        <!-- <div class="d-flex justify-end">
          <v-btn
            color="error"
            :disabled="!(inputs.length > 1)"
            @click="deleteTarget"
            text
          >
            <v-icon> mdi-close </v-icon>
            Delete target
          </v-btn>
          <v-btn
            outlined
            @click="
              () => {
                slide--;
              }
            "
            class="mx-2"
            :disabled="slide == 0"
            color="primary"
          >
            <v-icon> mdi-arrow-up </v-icon>
            Previous target
          </v-btn>

          <v-btn outlined @click="nextTarget" color="primary">
            <v-icon> mdi-arrow-down </v-icon>
            Next target
          </v-btn>
        </div> -->
      </v-card-text>
      <v-divider></v-divider>
      <div class="d-flex align-center justify-end py-3 px-3">
        <v-btn color="error" class="mx-1" outlined @click="dialog = !dialog">
          <f-icon icon="times-circle" />
          {{ $t("cancel") }}</v-btn
        >
        <v-btn color="primary" :loading="loading" @click="makeTarget()">
          <v-icon>mdi-plus</v-icon>
          {{ $t("save") }}</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  computed: {},
  data() {
    return {
      search: "",
      users: [],
      menu: false,
      loading: false,
      products: [],
      allCompany: false,
      slide: 1,
      searchProduct: "",
      inputs: {
        productId: "",
        type: 0,
        value: 0,
        note: "",
        dueDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        users: [],
      },

      companies: [],
    };
  },
  created() {
    this.loading = true;
    this.$http
      .get("/Companies", {
        params: {
          OfficeId: this.$store.state.userInfo.office.id,
        },
      })
      .then((com) => {
        this.companies = com.data.result;
        this.$http
          .get("/Users", {
            params: {
              OfficeId: this.$store.state.userInfo.office.id,
            },
          })
          .then((res) => {
            this.users = res.data.data;
            this.$http
              .get("/Products", {
                params: {
                  OfficeId: this.$store.state.userInfo.office.id,
                },
              })
              .then((res) => {
                this.products = res.data.data;
                this.loading = false;
              });
          });
        // this.checkIsEdit();
      });
  },
  mounted() {},
  props: ["dialog"],
  methods: {
    nextTarget() {
      if (this.slide == this.inputs.length - 1) {
        this.inputs.push({
          productId: "",
          userId: "",
          value: 0,
          note: "",
          dueDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        });
      }
      this.slide += 1;
    },
    deleteTarget() {
      this.inputs.splice(this.slide, 1);
      this.slide -= 1;
    },
    async makeTarget() {
      var val = this.$refs.makeTarget.validate();
      if (val) {
        this.loading = true;

        for (var i = 0; i < this.inputs.users.length; i++) {
          this.inputs.users[i] = {
            userId: this.inputs.users[i],
          };
        }
        var send = this.inputs;

        send.value = send.value.toString().replace(/,/g, "");
        this.$http
          .post("/targets/post", send)
          .then(() => {
            this.$emit("added", !this.dialog);
            this.dialog = false;
          })
          .catch((e) => {
            this.$store.commit("UPDATE_SNACKBAR", true);
            this.$store.commit(
              "UPDATE_SNACKBAR_MESSAGE",
              e.response.data.message
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    deleteUser(index) {
      this.inputs.users.splice(index, 1);
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) this.$refs.makeTarget.reset();
      this.$emit("updated", this.dialog);
    },
    search() {
      if (this.search !== "" && this.search !== null) {
        this.loading = true;
        this.$http.get("/Users?Search=" + this.search).then((res) => {
          this.users = res.data.data;
          this.loading = false;
        });
      }
    },
    searchProduct() {
      if (this.searchProduct !== "" && this.searchProduct !== null) {
        this.loading = true;
        this.$http
          .get("/Products", {
            params: {
              Search: this.searchProduct,
              OfficeId: this.$store.state.userInfo.office.id,
            },
          })
          .then((res) => {
            this.products = res.data.data;
            this.loading = false;
          });
      }
    },
  },
};
</script>
<style scoped>
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin: 0px !important;
}
.v-input__slot {
  margin-bottom: 0px !important;
  padding: 0px !important;
}
</style>
