<template>
  <div>
    <make-target
      @updated="
        (_) => {
          dialog = _;
        }
      "
      @added="
        (_) => {
          dialog = _;
          getTargets();
        }
      "
      :dialog="dialog"
    />
    <div class="d-flex align-content-center justify-space-between">
      <h1 class="mb-3">{{ $t("targets") }}</h1>
      <v-btn x-large color="secondary" @click="dialog = !dialog">
        <v-icon>mdi-plus</v-icon>
        {{ $t("make-a-target") }}
      </v-btn>
    </div>
    <v-card :loading="loading" class="transparent">
      <v-row>
        <v-col cols="3">
          <label for="se">{{ $t("search") }}</label>
          <v-text-field
            hide-detials="auto"
            class="mt-2"
            :label="$t('search')"
            id="se"
            solo
            :loading="loading"
            clearable
            v-model="options.Search"
            append-icon="mdi-magnify"
            type="search"
          ></v-text-field>
        </v-col>
        <v-col cols="12" style="padding-top: 0">
          <v-chip-group
            v-model="status"
            @change="getTargets()"
            :loading="loading"
            active-class="success"
          >
            <v-chip
              x-large
              v-for="(item, index) in statues"
              :key="index"
              filter
              :value="item.key"
            >
              {{ item.text }}
            </v-chip>
          </v-chip-group>
        </v-col>
        <v-col cols="12">
          <v-data-table
            disable-pagination
            :headers="headers"
            :items="targets"
            :no-data-text="$t('no-data')"
            :hide-default-footer="true"
          >
            <template v-slot:item.userFullName="{ item }">
              {{
                item.userFullName !== null
                  ? item.userFullName
                  : item.companyName
              }}
            </template>
            <template v-slot:item.dueDate="{ item }">
              {{ $service.formatDate(new Date(item.dueDate)) }}
            </template>
            <template v-slot:item.isDone="{ item }">
              <template
                v-if="new Date(item.dueDate).getTime() < new Date().getTime()"
              >
                <v-chip label :color="item.isDone ? 'success' : 'error'">
                  {{ item.isDone ? $t('done') : $t('overdue') }}
                </v-chip>
              </template>
              <template v-else>
                <v-chip label :color="item.isDone ? 'success' : 'warning'">
                  {{ item.isDone ? $t('done') : $t('waiting') }}
                </v-chip>
              </template>
            </template>
          </v-data-table>
          <div class="d-flex justify-center">
            <v-pagination
              v-model="options.PageIndex"
              :length="parseInt(total / options.PageSize) + 1"
            />
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
// import MakeTarget component
import MakeTarget from "./MakeTarget.vue";
export default {
  name: "Targets",
  components: { MakeTarget },
  data() {
    return {
      options: {
        Search: "",
        PageIndex: 1,
        PageSize: 10,
      },
      dialog: false,
      loading: false,
      targets: [],
      total: 0,
      status: "all",
      statues: [
        {
          text: "All",
          key: "all",
        },
        {
          text: "Waiting",
          key: false,
        },
        {
          text: "Done",
          key: true,
        },
      ],
      headers: [
        {
          text: this.$t('products'),
          value: "product.tradeName",
        },
        {
          text: this.$t('target-number'),
          value: "targetNumber",
        },
        {
          text:this.$t('current'),
          value: "current",
        },
        {
          text: this.$t('due-date'),
          value: "dueDate",
        },
        {
          text: this.$t('to'),
          value: "user.fullName",
        },
      ],
    };
  },
  created() {
    this.getTargets();
  },
  methods: {
    getTargets() {
      this.loading = true;
      var options = {};
      if (this.options.Search !== null && this.options.Search !== "") {
        options.Search = this.options.Search;
      }
      if (this.status !== "all") {
        options.isDone = this.status;
      }

      this.$http.get("/targets", { params: options }).then((response) => {
        this.total = response.data.count;
        this.targets = response.data.data;
        for (let i = 0; i < this.targets.length; i++) {
          Object.keys(this.targets[i]).forEach((key) => {
            if (typeof this.targets[i][key] == "number") {
              this.targets[i][key] = this.$service.formatCurrency(
                this.targets[i][key]
              );
            }
          });
        }
        this.loading = false;
      });
    },
  },
  mounted() {
    this.options.Search = "";
  },
  watch: {
    options: {
      handler() {
        this.getTargets();
      },
      deep: true,
    },
  },
};
</script>
