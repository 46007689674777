<template>
  <v-dialog persistent v-model="dialog" width="800">
    <v-card :loading="loading" outlined>
      <v-card-title class="text-h5 my-3">
        {{ $t('add-event') }}
        <v-spacer></v-spacer>
        <v-btn @click="dialog = !dialog" text color="error">
          <f-icon icon="times-circle" />
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-10">
        <v-form :loading="loading">
          <v-row>
            <v-col cols="6">
              <v-text-field
                hide-detials="auto"
                :label="$t('details')"
                outlined
                v-model="inputs.details"
                :rules="[(v) => !!v || $t('this-field-is-requird')]"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    hide-detials="auto"
                    outlined
                    v-model="inputs.date"
                    :label="$t('date')"
                    :rules="[(v) => !!v || $t('this-field-is-requird')]"
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="inputs.date"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-select
                outlined
                :items="types"
                v-model="inputs.workPlanTypeId"
                :label="$t('work-plan')"
                item-value="id"
                item-text="name"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                outlined
                :label="$t('users')"
                no-data-text="Write to search.."
                :items="users"
                item-text="fullName"
                v-model="inputs.userMention"
                multiple
                :search-input.sync="search"
                item-value="id"
                :loading="loading"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    {{ data.item.fullName }}
                    <small class="mx-1">{{ data.item.userTypeName }}</small>
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.fullName"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.userTypeName"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <div class="d-flex align-center justify-end py-3 px-3">
        <v-btn color="error" class="mx-1" outlined @click="dialog = !dialog">
          <f-icon icon="times-circle" />
          {{ $t('cancel') }}</v-btn
        >
        <v-btn color="primary" :loading="loading" @click="addEvent">
          <v-icon>mdi-plus</v-icon>
          {{ $t('save') }}</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      search: "",
      inputs: {
        details: "",
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        workPlanTypeId: "",
        userMention: [],
      },
      types: [],
      users: [],
    };
  },
  created() {
    this.loading = true;
    this.$http.get("/WorkPlanTypes").then((res) => {
      this.types = res.data.result;
      this.$http.get("/Users",{
      params:{
        OfficeId: this.$store.state.userInfo.office.id,
      }
    }).then((res) => {
        this.users = res.data.data;
        this.loading = false;
      });
    });
  },
  methods: {
    remove(item) {
      const index = this.inputs.userMention.indexOf(item.id);
      if (index >= 0) this.inputs.userMention.splice(index, 1);
    },
    async addEvent() {
      this.loading = true;
      for (let i = 0; i < this.inputs.userMention.length; i++) {
        this.inputs.userMention[i] = {
          userId: this.inputs.userMention[i],
          note: "",
        };
      }
      try {
        await this.$http.post("/WorkPlans/post", this.inputs);
        
        this.$emit("added", !this.dialog);
        this.dialog = false;
      } catch (e) {
        this.$store.commit("UPDATE_SNACKBAR", true);

        this.$store.commit("UPDATE_SNACKBAR_MESSAGE", e.response.data.message);
      }
      this.loading = false;
    },
  },
  props: ["dialog"],
  watch: {
    dialog() {
      if (this.dialog) {
        this.$http.get("/WorkPlanTypes").then((res) => {
          this.types = res.data.result;
          this.loading = false;
        });
      }
      this.$emit("updated", this.dialog);
    },
    search() {
      if (this.search !== "" && this.search !== null) {
        this.loading = true;
        this.$http.get("/Users?Search=" + this.search).then((res) => {
          this.users = res.data.data;
          this.loading = false;
        });
      }
    },
  },
};
</script>