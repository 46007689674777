<template>
  <div>
    <add-event
      :dialog="addEvent"
      @updated="
        (_) => {
          addEvent = _;
        }
      "
      @added="
        (_) => {
          addEvent = _;
          getEvents();
        }
      "
    />
    <add-work-plan
      :dialog="addWorkPlan"
      @updated="
        (_) => {
          addWorkPlan = _;
        }
      "
      @added="
        (_) => {
          addWorkPlan = _;
          getTypes();
        }
      "
    />
    <v-row>
      <v-col cols="12" md="8">
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              {{ $t('today') }}
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="addEvent = !addEvent" color="secondary">
              {{ $t('add-event') }}
            </v-btn>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <!-- <v-calendar
            ref="calendar"
            locale="en"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
          ></v-calendar> -->
          <v-calendar
            ref="calendar"
            v-model="value"
            :weekdays="weekday"
            :type="type"
            :event-more="true"
            event-more-text="More"
            :events="events"
            :event-overlap-mode="mode"
            :event-overlap-threshold="30"
            :event-color="getEventColor"
            @change="getEvents"
            :locale="$i18n.locale"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card
              color="grey lighten-4"
              :loading="selectedLoading"
              min-width="350px"
              flat
            >
              <v-toolbar :color="selectedEvent.color" dark>
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text class="mb-3">
                {{ $t('details') }} :
                <p class="text-h6">
                  {{ selectedEvent.details }}
                </p>
                <span class="text-h6">{{ $t('users') }} : </span>
                <div class="d-flex flex-column mt-2">
                  <div
                    class="d-flex flex-column mb-2 mt-2"
                    v-for="mention in mentions"
                    :key="mention.id"
                  >
                    <h3>{{ mention.fullName }}</h3>
                    <p>{{ mention.userTypeName }}</p>

                    <v-divider></v-divider>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn text color="secondary" @click="selectedOpen = false">
                  {{ $t('cancel') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="4">
        <v-card color="transparent">
          <v-card-title>
            {{ $t('work-plans') }}
            <v-spacer></v-spacer>
            <v-btn @click="addWorkPlan = !addWorkPlan" color="secondary">
              <v-icon class="mr-2">mdi-plus-circle</v-icon>
              this.$t('add-plan')
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div v-for="type in types" :key="type.id">
              <h2 class="mt-2 py-2 ">
                {{ type.name }}
              </h2>
              <span dir="ltr">{{
                $service.formatDate(new Date(type.created))
              }}</span>
              <v-divider></v-divider>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AddEvent from "./AddEvent.vue";
import AddWorkPlan from "./AddWorkPlan.vue";
export default {
  components: { AddEvent, AddWorkPlan },
  data: () => ({
    addEvent: false,
    addWorkPlan: false,
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
    },
    mode: "stack",
    value: "",
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    weekday: [0, 1, 2, 3, 4, 5, 6],
    selectedLoading: false,
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    types: [],
    // names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
  }),
  computed: {
    mentions() {
      if (this.selectedEvent.data !== undefined) {
        return this.selectedEvent.data.userMentions;
      } else {
        return [];
      }
    },
  },
  created() {
    this.getTypes();
  },
  mounted() {
    this.getEvents();
  },
  watch: {
    selectedOpen() {
      if (this.selectedOpen) {
        this.selectedLoading = true;
        this.$http.get("/workplans/" + this.selectedEvent.id).then((res) => {
          this.selectedEvent.data = res.data.result;
          this.selectedLoading = false;
        });
      }
    },
  },
  methods: {
    getTypes() {
      this.$http.get("/WorkPlanTypes").then((res) => {
        this.types = res.data.result;
      });
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    // getEvents() {
    //   this.events = [];
    //   const min = new Date(`2022-04-01T00:00:00`);
    //   const max = new Date(`2022-04-28T23:59:59`);
    //   // const eventCount = this.rnd(days, days + 20)
    //   this.$http.get("/workPlans").then((res) => {
    //     res.data.result.forEach((ev) => {
    //       // this.events.push({
    //       //   id: ev.id,
    //       //   name: ev.workPlanType,
    //       //   data: {},
    //       //   details: ev.details,
    //       //   start: new Date(ev.date),
    //       //   end: new Date(ev.date),
    //       //   color: this.colors[this.rnd(0, this.colors.length - 1)],
    //       // });
    //       const allDay = this.rnd(0, 3) === 0;
    //       const firstTimestamp = this.rnd(min.getTime(), max.getTime());
    //       const first = new Date(firstTimestamp - (firstTimestamp % 900000));
    //       const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
    //       const second = new Date(first.getTime() + secondTimestamp);
    //       this.events.push({
    //         name: ev.workPlanType,
    //         start: first,
    //         end: second,
    //         color: this.colors[this.rnd(0, this.colors.length - 1)],
    //         timed: !allDay,
    //       });
    //     });

    //     this.$refs.calendar.checkChange();
    //   });
    // },
    getEvents() {
      const events = [];

      const min = new Date(`2022-04-01T00:00:00`);
      const max = new Date(`2022-04-28T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          name: "test" + i,
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        });
      }

      this.events = events;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
};
</script>

<style scoped></style>
